import "./css/global.scss";
import "./index.scss";
import NavBar from "./components/NavBar.js";
import { setupNavBar } from "./pages/utils/setup.js";


//==================================================
// Code that must be added to each page that will utilize the
// NavBav web component.
let navbarElem = document.querySelector("nav-bar");
setupNavBar(navbarElem);
// End of Navbar related setup code.
//====================================================
